@import url(https://fonts.googleapis.com/css?family=Black+Han+Sans|Do+Hyeon|Jua|Nanum+Gothic|Song+Myung&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merienda:700|Work+Sans:600&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: "Noto Sans KR", sans-serif;
  background: #2a2b40;
  font-size: 12px; }

h2 {
  margin-bottom: 20px;
  font-size: 16px; }

.inputWrap {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 2px 8px;
  border-radius: 5px;
  background: #fff; }
  .inputWrap > * {
    height: 40px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    color: #2a2b40; }
  .inputWrap label {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    width: 100px;
    padding: 0 15px;
    border-right: 1px solid #dddddd;
    border-radius: 5px 0 0 5px;
    font-weight: bold; }
  .inputWrap input,
  .inputWrap select {
    width: 140px;
    padding: 5px 10px;
    border: none;
    border-radius: 0 5px 5px 0; }
  .inputWrap .w100 {
    width: 79%; }

.row {
  display: flex;
  justify-content: space-between; }
  .row > div {
    justify-content: space-between; }

#root {
  color: #ffffff; }
  #root .mainWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    height: calc(100vh + 60px);
    flex-direction: column; }
    #root .mainWrap > * {
      padding: 20px; }
    #root .mainWrap .perview p {
      margin: 10px 0; }
  #root .SizeControler {
    overflow: hidden; }
  #root .bgControler {
    width: 560px;
    margin: 0 10px; }
    #root .bgControler .bgColorWrap {
      padding: 15px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 2px 8px;
      border: 1px solid #fff;
      border-radius: 5px; }
      #root .bgControler .bgColorWrap > div {
        float: none;
        margin: 20px; }
      #root .bgControler .bgColorWrap .colorControler > div {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 2px 8px; }
      #root .bgControler .bgColorWrap .colorControler > div:last-child {
        margin: 20px 10px; }
  #root .textContents .inputWrap input {
    min-width: 400px;
    max-width: 100%; }
  #root .downLoadBtn button {
    width: 200px;
    height: 40px;
    font-size: 16px;
    border-radius: 5px; }
  #root .twitter-picker div > span > div {
    border: 1px solid #ddd; }

