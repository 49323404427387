@import 'utils';
@import url("https://fonts.googleapis.com/css?family=Black+Han+Sans|Do+Hyeon|Jua|Nanum+Gothic|Song+Myung&display=swap");
@import url("https://fonts.googleapis.com/css?family=Merienda:700|Work+Sans:600&display=swap");
@import "reset";

body {
  font-family: "Noto Sans KR", sans-serif;
  background: $mainColor;
  font-size: 12px;
  
}

h2 {
  margin-bottom: 20px;
  font-size: 16px;
}

.inputWrap {
  @include shadow;
  border-radius: 5px;
  background: #fff;
  > * {
    height: 40px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    color: $mainColor;
  }
  label {
    @include center;
    float: left;
    width: 100px;
    padding: 0 15px;
    border-right: 1px solid $line;
    border-radius: 5px 0 0 5px;
    font-weight: bold;
  }
  input,
  select {
    width: 140px;
    padding: 5px 10px;
    border: none;
    border-radius: 0 5px 5px 0;
  }
  .w100 {
    width: 79%;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  > div {
    justify-content: space-between;
  }
}

#root {
  color: #ffffff;
  .mainWrap {
    @include center;
    padding:30px 0;
    height: calc(100vh + 60px);
    flex-direction: column;
    > * {
      padding: 20px;
    }
    .perview p {
      margin: 10px 0;
    }
  }
  
  .SizeControler {
    overflow: hidden;
  }
  .bgControler {
    width: 560px;
    margin: 0 10px;
    .bgColorWrap {
      padding: 15px;
      @include shadow;
      border: 1px solid #fff;
      border-radius: 5px;
      > div {
        float: none;
        margin: 20px;
      }
      .colorControler > div {
        @include shadow;
      }
      .colorControler > div:last-child {
        margin: 20px 10px;
      }
    }
  }
  .textContents {
    .inputWrap {
      input {
        min-width: 400px;
        max-width:100%;
      }
    }
  }
  .downLoadBtn {
    button {
      width: 200px;
      height:40px;
      font-size: 16px;
      border-radius: 5px;
    }
  }
  .twitter-picker div > span > div {
    border:1px solid #ddd
  }
}
